import Header from '@/components/common/Header'
import '@/styles/globals.css'
import "@/styles/marqueefy.min.css"
import "@/styles/animate.min.css"
import Footer from '@/components/sections/Footer'
import React, { useEffect } from 'react'
import PropTypes from "prop-types";
import NextNProgress from "nextjs-progressbar";
import { Inter } from 'next/font/google'
import Head from 'next/head';

const inter = Inter({
  weight: ['400', '600', '800'],
  subsets: ['latin']
});

function MyApp({ Component, pageProps, services }) {

  useEffect(() => {
    setTimeout(() => {
      if (typeof window !== "undefined") {
        const loader = document.getElementById("globalLoader");
        if (loader) loader.classList.add("not-showing");
      }
    }, 500);
  }, []);

  return (
    <>
      <Head>

      </Head>
      <div className={inter.className}>
        <Header />
        <NextNProgress color="#006fba" />
        <Component {...pageProps} />
        <Footer data={services} />
      </div>
    </>
  )
}
MyApp.getInitialProps = async () => {
  try {
    const resServices = await fetch(
      `${process.env.NEXT_PUBLIC_API}/api/v1/services`
    );
    const services = await resServices.json();

    return { services };

  } catch (error) {
    return {
      props: {
        services: null,
      },
    };
  }
};

export default MyApp;
MyApp.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.any,
  services: PropTypes.any,
};