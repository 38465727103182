import Image from "next/image";
import Link from "next/link";
import React from "react";
import PropTypes from "prop-types";
const navigationItems = require("../../data/navigation.json");

export default function Footer({ data }) {
  return (
    <>
      <footer className=" relative flex items-center justify-center px-5 py-10 bg-gray-light !m-0">
        <div className="w-full max-w-xl mx-auto md:max-w-5xl 2xl:max-w-screen-2xl">
          <div className="flex items-center justify-between">
            <div>
              <Image
                width={200}
                height={200}
                src="/images/logo-yellow.png"
                className="w-24 "
                alt="Actionis Consulting Inc Logo"
              />
            </div>
            <div className="flex items-center">
              <div className="flex justify-center w-full gap-2 ">
                <Link
                  className="relative text-xs font-medium text-blue-light md:text-sm md:hidden lg:inline-block group focus:outline-none focus:ring "
                  href="https://app.actionis-consulting.com/"
                  target="_blank"
                  rel="nofollow"
                >
                  <span className="absolute inset-0 transition-transform translate-x-0 translate-y-0 bg-white rounded-md group-hover:translate-y-2 group-hover:translate-x-2" />
                  <span className="relative flex items-center px-3 py-3 rounded-md font-bold bg-yellow-dark gap-x-2">
                    <span>Portal</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="hidden md:block"
                      width="1.5em"
                      height="1.5em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4m-5-4l5-5l-5-5m5 5H3"
                      />
                    </svg>
                  </span>
                </Link>
                <Link
                  target="_blank"
                  aria-label="calendy"
                  rel="nofollow"
                  className="relative text-xs font-medium text-blue-light md:text-sm md:hidden lg:inline-block group focus:outline-none focus:ring "
                  href="https://calendly.com/melanie-actionis/30min?"
                >
                  <span className="absolute inset-0 transition-transform translate-x-0 translate-y-0 bg-white rounded-md group-hover:translate-y-2 group-hover:translate-x-2" />
                  <span className="relative flex px-3 py-3 rounded-md font-bold bg-yellow-dark gap-x-2">
                    <span className="whitespace-nowrap">Agendar llamada</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="hidden md:block"
                      width="1.5em"
                      height="1.5em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="m18 10l-1.45-1.4l1.6-1.6H13V5h5.15L16.6 3.45L18 2l4 4.05zm1.95 11q-3.125 0-6.175-1.362t-5.55-3.863t-3.862-5.55T3 4.05q0-.45.3-.75t.75-.3H8.1q.35 0 .625.238t.325.562l.65 3.5q.05.4-.025.675T9.4 8.45L6.975 10.9q.5.925 1.187 1.787t1.513 1.663q.775.775 1.625 1.438T13.1 17l2.35-2.35q.225-.225.588-.337t.712-.063l3.45.7q.35.1.575.363T21 15.9v4.05q0 .45-.3.75t-.75.3M6.025 9l1.65-1.65L7.25 5H5.025q.125 1.025.35 2.025T6.025 9m8.95 8.95q.975.425 1.988.675T19 18.95v-2.2l-2.35-.475zm0 0"
                      />
                    </svg>
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="w-full bg-white h-[3px] rounded-full my-10" />
          <div className="grid grid-cols-1 lg:grid-cols-2 pb-5">
            <div className="flex justify-between  sm:gap-x-10 pb-[2.5rem] border-b lg:border-b-0 lg:pb-0 lg:pr-[2.5rem] lg:border-r">
              <div>
                <p className="text-lg text-white max-w-prose">
                  Servicios especializados en creación de nuevos
                  emprendimientos, contabilidad, impuestos e imagen corporativa.
                </p>
                <div>
                  <p className="mt-16 mb-5 text-xs text-white font-bold md:text-sm">
                    Contacto
                  </p>
                  <div>
                    <span className="flex flex-col text-xs text-white md:text-sm gap-y-1">
                      <span className="flex ">
                        <a
                          href="tel:+17865885320"
                          className="mr-1 whitespace-nowrap"
                        >
                          +1 (786) 588-5320
                        </a>
                      </span>
                      <a
                        href="mailto:actionisconsultingcorp@gmail.com"
                        className="w-fit"
                      >
                        actionisconsultingcorp@gmail.com
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <p className="mb-5 text-xs text-white font-bold md:text-sm">
                  Navegación
                </p>
                <ul className="flex flex-col !list-none gap-y-4">
                  {navigationItems.map((item, index) => (
                    <li
                      key={index}
                      className="text-sm text-white transition-all duration-200 ease-in-out hover:text-gray-300"
                    >
                      <Link href={item.link}>{item.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="flex justify-start flex-wrap lg:flex-nowrap gap-x-10 items-start pt-[2.5rem] border-t lg:border-t-0 lg:pt-0 lg:pl-[2.5rem] lg:border-l">
              {data && data.length && (
                <div className="flex flex-col items-start justify-start md:h-full md:w-full">
                  <p className="mb-5 text-xs text-white font-bold md:text-sm">
                    Servicios
                  </p>
                  <div>
                    <ul className="flex flex-col text-xs text-white !list-none md:text-sm gap-y-4 md:gap-y-3">
                      {data.slice(0, 5).map((item, index) => (
                        <li
                          key={index}
                          className="transition-all duration-200 ease-in-out hover:text-gray-300"
                        >
                          {item.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              <div className="flex flex-col items-start justify-start h-full md:hidden">
                <p className="mb-5 text-sm font-bold text-white md:text-base">
                  Navegación
                </p>
                <ul className="flex flex-col !list-none gap-y-4 mb-5">
                  {navigationItems.map((item, index) => (
                    <li
                      key={index}
                      className="text-xs text-white transition-all duration-200 ease-in-out md:text-sm hover:text-gray-300"
                    >
                      <Link href={item.link}>{item.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex justify-center w-full lg:w-1/2 lg:justify-end lg:items-start lg:h-full">
                <div className="flex items-center justify-center gap-x-4">
                  <Link
                    href="https://www.facebook.com/actionis.consulting.corp"
                    aria-label="facebook"
                    target="_blank"
                    className="flex items-center justify-center w-12 h-12 md:w-8 md:h-8 rounded-full bg-blue-light relative p-2"
                  >
                    <Image
                      fill
                      src="/icons/fb.svg"
                      className="object-contain p-2 md:p-1.5"
                      alt="Icono Facebook"
                    />
                  </Link>
                  <a
                    href="
                      https://www.instagram.com/actionis.consulting"
                    target="_blank"
                    aria-label="Instagram"
                    className="flex items-center justify-center w-12 h-12 md:w-8 md:h-8 rounded-full bg-blue-light relative p-2"
                  >
                    <Image
                      fill
                      src="/icons/ig.svg"
                      className="object-contain p-2 md:p-1.5"
                      alt="Icono Instagram"
                    />
                  </a>
                  <Link
                    href="https://www.youtube.com/@actionisconsultingcorp.5571"
                    target="_blank"
                    aria-label="Youtube"
                    className="flex items-center justify-center w-12 h-12 md:w-8 md:h-8 rounded-full bg-blue-light relative p-2"
                  >
                    <Image
                      fill
                      src="/icons/yt.svg"
                      className="object-contain p-2 md:p-1.5"
                      alt="Icono Youtube"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-auto bg-white h-[3px] rounded-full my-10" />
          <div className="flex flex-col items-center justify-between gap-y-5 md:flex-row text-xs md:text-sm">
            <div>
              <p className="text-white">
                Todos los derechos reservados. {" "}
                <span className="font-bold text-yellow-dark">
                  Actionis Consulting
                </span>
              </p>
            </div>
            <div>
              <p className="flex justify-cente text-white gap-x-3">
                Sitio web desarrollado por{" "}
                <Link
                  href="https://colmenadigital.net"
                  target="_blank"
                  className="w-32"
                >
                  <Image
                    width={200}
                    height={200}
                    src="/images/colmena-digital.svg"
                    className="object-contain w-full h-full"
                    alt="Colmena Digital"
                  />
                </Link>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

Footer.propTypes = {
  data: PropTypes.any,
};
