import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

const navigationItems = require("../../data/navigation.json");

export default function Header() {
  function addClassToNavigation() {
    var navigation = document.getElementById("mobileNavigation");
    navigation.classList.toggle("showMobileMenu");
  }

  const [isInTop, setIsInTop] = useState(true);
  const router = useRouter();

  useEffect(() => {
    let prevScrollpos = window.pageYOffset;
    let currentScrollPos = window.pageYOffset;
    window.onscroll = function () {
      if (window.scrollY <= 10) {
        setIsInTop(true);
      } else if (prevScrollpos < currentScrollPos) {
        setIsInTop(false);
      } else {
        setIsInTop(false);
      }
      prevScrollpos = currentScrollPos;
    };
  }, []);

  return (
    <header
      className="header"
    >
      <header
        className={` ${isInTop ? "p-5 " : "p-0"
          } headroom-header transition-all duration-200 ease-in-out`}
      >
        <div className="relative flex justify-between ">
          <div
            className={`flex justify-between w-full !px-5 max-h-[130px] ${isInTop ? "rounded-3xl " : ""
              } transition-all duration-500 ease-in-out headroom-header-container  bg-yellow-dark backdrop-blur-md  shadow-2xl `}
          >
            <div className="flex items-center justify-start md:py-2 gap-x-5 w-fit">
              <Link
                href="/#hero"
                className="relative object-contain h-20 w-14 md:w-28 md:h-28 mr-7 "
              >
                <Image
                  src="/images/logo-blue-white.png"
                  className="object-contain object-left py-2 md:py-0"
                  alt="Actionis Consulting Inc Logo"
                  fill
                  priority
                />
              </Link>
            </div>
            <div className="flex mr-auto">
              <ul className="items-center justify-center hidden h-full mr-5 font-bold lg:flex ">
                {navigationItems.map((item, index) => (
                  <li
                    key={index}
                    className={`flex items-center h-full px-2 lg:px-3 xl:px-4 select-none text-sm transition-all duration-200 border-b-4 border-transparent xl:text-base ${router.pathname === item.link
                      ? "bg-blue-light text-yellow-dark pointer-events-none"
                      : "text-blue-light hover:bg-blue-light hover:text-yellow-dark"
                      }`}
                  >
                    <Link className="flex items-center h-full" href={item.link}>
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex items-center justify-between w-full lg:w-fit lg:justify-end gap-x-5">
              <div className="flex justify-center w-full gap-2 md:justify-end ">
                <Link
                  className="relative text-xs font-bold text-white xl:text-sm lg:inline-block group focus:outline-none focus:ring "
                  href="https://app.actionis-consulting.com/"
                  target="_blank"
                  rel="nofollow"
                >
                  <span className="absolute inset-0 transition-transform translate-x-0 translate-y-0 bg-white rounded-md group-hover:translate-y-2 group-hover:translate-x-2" />
                  <span className="relative flex items-center px-3 py-3 rounded-md bg-blue-light gap-x-2">
                    <span>Portal</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="hidden md:block"
                      width="1.5em"
                      height="1.5em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4m-5-4l5-5l-5-5m5 5H3"
                      />
                    </svg>
                  </span>
                </Link>
                <Link
                  target="_blank"
                  aria-label="calendy"
                  className="relative text-xs font-bold text-white xl:text-sm lg:inline-block group focus:outline-none focus:ring "
                  href="https://calendly.com/melanie-actionis/30min?"
                  rel="nofollow"
                >
                  <span className="absolute inset-0 transition-transform translate-x-0 translate-y-0 bg-white rounded-md group-hover:translate-y-2 group-hover:translate-x-2" />
                  <span className="relative flex px-3 py-3 rounded-md bg-blue-light gap-x-2">
                    <span className="whitespace-nowrap">Agendar llamada</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="hidden md:block"
                      width="1.5em"
                      height="1.5em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="m18 10l-1.45-1.4l1.6-1.6H13V5h5.15L16.6 3.45L18 2l4 4.05zm1.95 11q-3.125 0-6.175-1.362t-5.55-3.863t-3.862-5.55T3 4.05q0-.45.3-.75t.75-.3H8.1q.35 0 .625.238t.325.562l.65 3.5q.05.4-.025.675T9.4 8.45L6.975 10.9q.5.925 1.187 1.787t1.513 1.663q.775.775 1.625 1.438T13.1 17l2.35-2.35q.225-.225.588-.337t.712-.063l3.45.7q.35.1.575.363T21 15.9v4.05q0 .45-.3.75t-.75.3M6.025 9l1.65-1.65L7.25 5H5.025q.125 1.025.35 2.025T6.025 9m8.95 8.95q.975.425 1.988.675T19 18.95v-2.2l-2.35-.475zm0 0"
                      />
                    </svg>
                  </span>
                </Link>
              </div>
              <svg
                onClick={() => addClassToNavigation()}
                className="min-w-32 min-h-32 icon--menu-toggle block text-blue-light lg:hidden z-[999999]"
                fill="currentcolor"
                viewBox="0 0 60 30"
              >
                <g className="icon-group">
                  <g
                    fill="currentcolor"
                    stroke="currentcolor"
                    className=""
                  >
                    <path d="M 6 0 L 54 0" />
                    <path d="M 6 15 L 54 15" />
                    <path d="M 6 30 L 54 30" />
                  </g>

                </g>
              </svg>
            </div>
          </div>
        </div>
        <nav
          id="mobileNavigation"
          className="main-navigation "
          style={{ zIndex: 10 }}
        >
          <svg
            onClick={() => addClassToNavigation()}
            className="w-32 h-32 icon--menu-toggle  absolute  top-0 right-0 m-10 lg:hidden  z-[999999]"
            fill="currentcolor"
            viewBox="0 0 60 30"
          >
            <g className="icon-group">
              <g
                fill="currentcolor"
                stroke="currentcolor"
                className="!text-white "
              >
                <path d="M 15 0 L 45 30" />
                <path d="M 15 30 L 45 0" />
              </g>
            </g>
          </svg>
          <ul>
            {navigationItems.map((item, index) => (
              <li key={index} onClick={() => addClassToNavigation()}>
                <Link href={item.link}>{item.name}</Link>
              </li>
            ))}
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                aria-label="Calendy"
                href="https://calendly.com/melanie-actionis/30min?"
              >
                Agendar llamada
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://app.actionis-consulting.com/"
              >
                Portal
              </a>
            </li>
          </ul>
        </nav>
      </header>
    </header>
  );
}
